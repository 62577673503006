import {Controller} from "stimulus"

export default class extends Controller {
    static get targets() {
        return ["wrapper"]
    }

    connect() {
        this.wrapper = $(this.wrapperTarget)
        this.columnWrapper = $(this.wrapper).parent()
        this.recommendationsList = this.columnWrapper.find(".recommendations-column-list")
        this.formWrapper = $("#filterrific_filter")
        this.wrapper.on('click', this._load_more_recommendations)

        this._find_last_contact_attempt()
    }

    disconnect() {
        this.wrapper.unbind('click')
    }

    _load_more_recommendations = () => {
        let recommendationsColumnId = this.wrapper.data('recommendationsColumnId')
        let allRecommendations = this.wrapper.data('allRecommendations')
        let recommendationsPerPage = this.wrapper.data('recommendationsPerPage')
        let nextPage = (this.recommendationsList.children().size() / recommendationsPerPage + 1).toFixed(0)

        Rails.ajax({
            url: window.location.href + '/load_more/' + recommendationsColumnId + '?' + this.formWrapper.serialize() + '&page=' + nextPage,
            type: 'get',
            success: this._show_recommendations
        });

        if(nextPage * recommendationsPerPage >= allRecommendations) {
            this.wrapper.remove()
        }
    }

    _show_recommendations = (recommendations) => {
        this.recommendationsList.append(recommendations.body.innerHTML)
        this._find_last_contact_attempt()
    }

    _find_last_contact_attempt = () => {
        this.columnWrapper.trigger('reloadLastContactAttempt')
    }
}