import {Controller} from "stimulus"

export default class extends Controller {
    static get targets() {
        return ["wrapper"]
    }

    connect() {
        this.wrapper = $(this.wrapperTarget)
        this.recommendationsList = this.wrapper.find(".recommendations-column-list")
        this._find_last_contact_attempt()

        this.wrapper.on('reloadLastContactAttempt', this._find_last_contact_attempt)
    }

    disconnect() {
        this.wrapper.unbind('reloadLastContactAttempt')
    }

    _find_last_contact_attempt = () => {
        let allLastContactAttemptTimes = this.recommendationsList.children().map(function() { return $(this).data('lastContactAttemptTime') } )
        let lastContactAttemptTime = Math.max.apply(Math, allLastContactAttemptTimes)

        this.recommendationsList.children().each(function () {
            if($(this).data('lastContactAttemptTime') === lastContactAttemptTime) {
                $(this).find('.recommendation-table-item-nav').addClass('recommendation-table-item-last-contact-attempt')
                $(this).find('.recommendation-table-item-nav').prop('title', 'W tej kolumnie to polecenie ma ostatnią próbę kontaktu')
                $(this).find('.recommendation-table-item').addClass('recommendation-table-item-last-contact-attempt')
            } else {
                $(this).find('.recommendation-table-item-nav').removeClass('recommendation-table-item-last-contact-attempt')
                $(this).find('.recommendation-table-item-nav').removeProp('title')
                $(this).find('.recommendation-table-item').removeClass('recommendation-table-item-last-contact-attempt')
            }
        })
    }
}